import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentUser } from '../ducks/user.duck';

const MAPBOX_SCRIPT_ID = 'mapbox_GL_JS';
const GOOGLE_MAPS_SCRIPT_ID = 'GoogleMapsApi';

export const IncludeScripts = props => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.currentUser);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  const { marketplaceRootURL: rootURL, maps, analytics } = props?.config || {};
  const { googleAnalyticsId, plausibleDomains } = analytics;
  const { mapProvider, googleMapsAPIKey, mapboxAccessToken } = maps || {};
  const isGoogleMapsInUse = mapProvider === 'googleMaps';
  const isMapboxInUse = mapProvider === 'mapbox';

  const hasGoogleAnalyticsv4Id = googleAnalyticsId?.indexOf('G-') === 0;

  let mapLibraries = [];
  let analyticsLibraries = [];
  if (isMapboxInUse) {
    mapLibraries.push(
      <script key="mapboxSDK" src={`${rootURL}/static/scripts/mapbox/mapbox-sdk.min.js`}></script>
    );
    mapLibraries.push(
      <link
        key="mapbox_GL_CSS"
        href="https://api.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.css"
        rel="stylesheet"
        crossOrigin
      />
    );
    mapLibraries.push(
      <script
        id={MAPBOX_SCRIPT_ID}
        key="mapbox_GL_JS"
        src="https://api.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.js"
        crossOrigin
      ></script>
    );
  } else if (isGoogleMapsInUse) {
    mapLibraries.push(
      <script
        id={GOOGLE_MAPS_SCRIPT_ID}
        key="GoogleMapsApi"
        src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}&libraries=places`}
        crossOrigin
      ></script>
    );
  }

  if (googleAnalyticsId && hasGoogleAnalyticsv4Id) {
    analyticsLibraries.push(
      <script
        key="gtag.js"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
        crossOrigin
      ></script>
    );
    const userId = currentUser?.id?.uuid;

    analyticsLibraries.push(
      <script key="gtag dataLayer">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${googleAnalyticsId}', {
          cookie_flags: 'SameSite=None;Secure',
          user_id: '${userId}',
          send_page_view: false
        });
        `}
      </script>
    );
  }

  if (plausibleDomains) {
    analyticsLibraries.push(
      <script
        key="plausible"
        defer
        src="https://plausible.io/js/script.js"
        data-domain={plausibleDomains}
        crossOrigin
      ></script>
    );
  }

  const isBrowser = typeof window !== 'undefined';
  const isMapboxLoaded = isBrowser && window.mapboxgl;

  if (isMapboxInUse && isMapboxLoaded && !window.mapboxgl.accessToken) {
    window.mapboxgl.accessToken = mapboxAccessToken;
  }

  const onMapLibLoaded = () => {
    if (isMapboxInUse && !window.mapboxgl.accessToken) {
      window.mapboxgl.accessToken = mapboxAccessToken;
    }
  };

  const onChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(s =>
        [MAPBOX_SCRIPT_ID, GOOGLE_MAPS_SCRIPT_ID].includes(s.id)
      );
      if (foundScript) {
        foundScript.addEventListener('load', onMapLibLoaded, { once: true });
      }
    }
  };

  const allScripts = [...analyticsLibraries, ...mapLibraries];
  return <Helmet onChangeClientState={onChangeClientState}>{allScripts}</Helmet>;
};
