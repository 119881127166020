import React, { useState, useRef, useEffect } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { HiOutlineCheckCircle, HiClipboardCheck } from 'react-icons/hi';
import { BiSupport } from 'react-icons/bi';
import { RiFolderReceivedLine } from 'react-icons/ri';
import { AiFillFire } from 'react-icons/ai';
import { IoDocumentText } from 'react-icons/io5';
import { RiGitMergeFill } from 'react-icons/ri';
import { MdChat } from 'react-icons/md';
import { TbLicense } from 'react-icons/tb';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import { types as sdkTypes } from '../../util/sdkLoader';
import _ from 'lodash';

const { Money } = sdkTypes;

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
};

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

const getPlan = type => {
  switch (type) {
    case 'monthly':
      return 'Monthly Plan';
    case 'annually':
      return 'Annually Plan';
    case 'source-code':
      return 'Source Code';
    default:
      return null;
  }
};

const getSourceCodeFullPrice = (pricingOptions, config, intl) => {
  if (!pricingOptions) {
    return 0; // Return zero if there are no pricing options
  }

  // Find the source code pricing from the options
  const sourceCodePricing = pricingOptions.find(item => item.type === 'source-code');

  if (!sourceCodePricing) {
    return 0; // Return zero if source code pricing is not found
  }

  // Assuming Money and priceData functions handle conversion to numeric values
  const price = new Money(sourceCodePricing.fee, sourceCodePricing.currency);
  const initial = sourceCodePricing.initialSetupFee
    ? new Money(sourceCodePricing.initialSetupFee, sourceCodePricing.currency)
    : new Money(0, price.currency); // Use zero if no initial fee

  const totalPrice = price.amount + initial.amount;

  const totalPriceToMoney = new Money(totalPrice, sourceCodePricing.currency);

  const { formattedPrice } = priceData(totalPriceToMoney, config.currency, intl);

  return formattedPrice;
};

const PriceList = props => {
  const { publicData, config, intl } = props;
  const { listingType, pricingOptions = null } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !pricingOptions) {
    return null;
  }

  return (
    <div style={{ marginTop: '24px' }}>
      {pricingOptions
        // TODO: allow only source code price until the white label functionality is in place
        .filter(item => item.type === 'source-code')
        .map((item, idx) => {
          const price = new Money(item.fee, item.currency);
          const initial = item.initialSetupFee
            ? new Money(item.initialSetupFee, item.currency)
            : null;
          let initialFee = initial
            ? priceData(initial, config.currency, intl).formattedPrice
            : null;
          const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
          const pp = `${formattedPrice}` + (initialFee ? ` +${initialFee} initial setup fee` : '');

          return (
            <div key={`${idx}-${item.fee}`}>
              <div className={css.title}>{getPlan(item.type)}</div>
              <div>
                <span className={css.title}>{formattedPrice}</span>&nbsp;
                {initialFee !== null && (
                  <span
                    className="textSmall"
                    style={{ color: '#aeabab' }}
                  >{`+ ${initialFee} initial fee`}</span>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

function getClassName(property) {
  return `${css.propItem} ${property ? css.notMissing : css.missing}`;
}

const DynamicHeightAnimatedDiv = ({ isExpanded, children }) => {
  const [maxHeight, setMaxHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, [children]);

  const style = {
    overflow: 'hidden',
    maxHeight: isExpanded ? maxHeight : '0px',
    transition: 'max-height 0.3s ease-in-out',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 40,
    marginTop: 25,
  };

  return (
    <div ref={contentRef} style={style} className={css.expandedContainer}>
      {children}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, description } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const priceString = getSourceCodeFullPrice(publicData.pricingOptions, config, intl);
  const [isExpanded, setIsExpanded] = useState(false);

  const legalOptions = [
    {
      name: 'Perpetual License, with rights to use and modify and integrate.',
      value: 'use-and-modify',
    },
    {
      name: 'Perpetual License, Right to Use only.',
      value: 'use-only',
    },
    {
      name: 'Full Transfer of Intellectual Property (IP)',
      value: 'full-rights',
    },
  ];

  // Function to toggle expanded content
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const firstIndustry = publicData?.Industry?.[0];
  const softwareType = publicData?.software_type;

  return (
    <div className={css.listingItem} onClick={toggleExpanded}>
      <div className={css.mainContentContainer}>
        {/* Image + title and description  */}
        <div className={css.leftContainer}>
          <img
            src={firstImage?.attributes?.variants['listing-card']?.url}
            alt="Product"
            className={css.productImg}
          />

          <div className={css.infoContainer}>
            <div className={css.titleAndDescriptionContainer}>
              <h2 className={css.listingTitle}>{title}</h2>

              <p className={css.description} style={{ margin: 0, padding: 0 }}>
                {description}
              </p>
            </div>
            <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
              {firstIndustry && (
                <span className={css.tag}>{_.startCase(_.toLower(firstIndustry))}</span>
              )}

              {softwareType && (
                <span className={css.tag}>{_.startCase(_.toLower(softwareType))}</span>
              )}
            </div>
          </div>
        </div>
        {/* View button + properties  */}
        <div className={css.rightContainer}>
          <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
            <button className={css.buttonListing}>View</button>
          </NamedLink>

          {priceString ? (
            <span className={css.priceText}>{priceString}</span>
          ) : (
            <span className={css.onlyListingTag}>Listing only</span>
          )}
          {/* PROPERTIES */}
          <div className={css.propsContainer}>
            <div className={getClassName(publicData?.isVerified)}>
              <HiOutlineCheckCircle style={{ fill: 'transparent', width: 16, height: 16 }} />
              <h5>Verified Listing</h5>
            </div>
            <div
              className={getClassName(publicData?.documentation_support?.includes('customizable'))}
            >
              <HiClipboardCheck style={{ width: 16, height: 16 }} />
              <h5>Customizable</h5>
            </div>
            <div className={getClassName(publicData?.documentation_support?.includes('support'))}>
              <BiSupport style={{ width: 16, height: 16 }} />
              <h5>Support available</h5>
            </div>
            <div className={getClassName(publicData?.hasSourceCode)}>
              <RiFolderReceivedLine style={{ width: 16, height: 16 }} />
              <h5>Instant delivery</h5>
            </div>
          </div>
        </div>
      </div>
      {/* Expanded info */}
      <DynamicHeightAnimatedDiv isExpanded={isExpanded}>
        <p style={{ margin: 0, padding: 0 }}>{publicData?.longDescription}</p>

        <div className={css.collapsedPropsContainer}>
          <h1>Support</h1>
          <div className={css.propsContainer} style={{ marginBottom: 10 }}>
            <div
              className={getClassName(publicData?.documentation_support?.includes('Installation'))}
            >
              <AiFillFire style={{ width: 16, height: 16 }} />
              <h5>Installation guaranteed</h5>
            </div>
            <div
              className={getClassName(publicData?.documentation_support?.includes('documentation'))}
            >
              <IoDocumentText style={{ width: 16, height: 16 }} />
              <h5>Documentation & help guide</h5>
            </div>
            <div className={getClassName(publicData?.documentation_support?.includes('updates'))}>
              <RiGitMergeFill style={{ width: 16, height: 16 }} />
              <h5>Updates available</h5>
            </div>
            <div
              className={getClassName(
                publicData?.documentation_support?.includes('onsite-support')
              )}
            >
              <MdChat style={{ width: 16, height: 16 }} />
              <h5>On site support(available upon request)</h5>
            </div>
          </div>
          {publicData?.code_license && (
            <>
              <h1>Legal</h1>
              <div className={css.propsContainer}>
                <div className={getClassName(true)}>
                  <TbLicense style={{ fill: 'transparent', width: 16, height: 16 }} />
                  <h5>
                    License type:{' '}
                    {legalOptions.find(item => item.value === publicData.code_license).name}
                  </h5>
                </div>
              </div>
            </>
          )}
        </div>
      </DynamicHeightAnimatedDiv>
    </div>

    // <div
    //   style={{
    //     display: 'flex',
    //     flexDirection: 'column',
    //     border: '1px solid #f1f1f1',
    //     borderRadius: 24,
    //     padding: '24px 40px',
    //   }}
    // >
    //   <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
    //     <div
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         gap: 15,
    //       }}
    //     >
    //       <img
    //         src={firstImage?.attributes?.variants['listing-card']?.url}
    //         style={{
    //           objectFit: 'contain',
    //           width: 50,
    //           height: 'auto',
    //           borderRadius: 5,
    //           borderWidth: 1,
    //         }}
    //         alt="product"
    //       />
    //       <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
    //         <div className={css.title}>
    //           {richText(title, {
    //             longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    //             longWordClass: css.longWord,
    //           })}
    //         </div>
    //         <div style={{ color: '#aeabab' }}>
    //           {/*{description}*/}
    //           {richText(truncateText(description, 103), {
    //             longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    //             longWordClass: css.longWord,
    //           })}
    //         </div>
    //         {/* <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} /> */}
    //       </div>
    //       {/* <div className={css.info} style={{ padding: 0 }}> */}
    //       {/* <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} /> */}
    //       {/* <div className={css.mainInfo}> */}
    //       {/* <div className={css.title}>
    //           {richText(title, {
    //             longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    //             longWordClass: css.longWord,
    //           })}
    //         </div> */}
    //       {/* {showAuthorInfo ? (
    //           <div className={css.authorInfo}>
    //             <FormattedMessage id="ListingCard.author" values={{ authorName }} />
    //           </div>
    //         ) : null} */}
    //       {/* </div> */}
    //       {/* </div> */}
    //     </div>
    //     {/* <AspectRatioWrapper
    //     className={css.aspectRatioWrapper}
    //     width={aspectWidth}
    //     height={aspectHeight}
    //     {...setActivePropsMaybe}
    //   >
    //     <LazyImage
    //       rootClassName={css.rootForImage}
    //       alt={title}
    //       image={firstImage}
    //       variants={variants}
    //       sizes={renderSizes}
    //     />
    //   </AspectRatioWrapper> */}
    //   </NamedLink>
    //   <PriceList price={price} publicData={publicData} config={config} intl={intl} />
    // </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
