import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import css from './PaymentCancellationPage.module.css';
import { H3, LayoutSingleColumn, PrimaryButton } from '../../components';
import { FormattedMessage } from 'react-intl';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PaymentCancellationPage = ({ className, rootClassName }) => {
  const classes = classNames(rootClassName || css.root, className);
  const history = useHistory();
  const query = useQuery();
  const productId = query.get('productId');

  useEffect(() => {
    const fromCheckout = sessionStorage.getItem('fromCheckout');
    if (!productId || fromCheckout !== 'true') {
      history.push('/');
    } else {
      // Remove the flag from session storage
      sessionStorage.removeItem('fromCheckout');
    }
  }, [productId, history]);

  return (
    <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
      <div className={classes}>
        <div className={css.content}>
          <H3 as="h1" className={css.title}>
            <FormattedMessage id="PaymentCancellationPage.title" />
          </H3>
          <p className={css.message}>
            <FormattedMessage id="PaymentCancellationPage.message" values={{ productId }} />
          </p>
          <PrimaryButton className={css.button} onClick={() => window.location.href = '/'}>
            <FormattedMessage id="PaymentCancellationPage.homeButton" />
          </PrimaryButton>
        </div>
      </div>
    </LayoutSingleColumn>
  );
};

PaymentCancellationPage.defaultProps = {
  className: null,
  rootClassName: null,
  productId: null,
};

PaymentCancellationPage.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  productId: PropTypes.string,
};

export default PaymentCancellationPage;
