/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemUnknownItemsMaybe = props => {
  const { lineItems, isProvider, code, intl, value } = props;
  const nonCommissionLineItems = lineItems.filter(
    item => item.code !== 'line-item/provider-commission'
  );

  return (
    <React.Fragment>
      {nonCommissionLineItems.map((item, index) => (
        <div key={index} className={css.lineItem}>
          <span className={css.itemLabel}>{humanizeLineItemCode(item.code)}</span>
          <span className={css.itemValue}>{formatMoney(intl, item.lineTotal)}</span>
        </div>
      ))}
    </React.Fragment>
  );
};

LineItemUnknownItemsMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
